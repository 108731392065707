/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif; /* Modern font */
  background-color: #f4f4f4; /* Light background color */
  color: #333; /* Dark text for contrast */
}

/* Homepage Container */
.homepage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #eceaea; /* White background for the main content */
  border-radius: 10px; /* Rounded corners */
}

/* Hero Section */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  text-align: center;
  z-index: 1; /* Ensures the content stays on top */
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Ensures the video stays behind the content */
}

.background-video {
  width: 100%;
  height: 85%;
  object-fit: cover; /* Makes the video fill the container */
  position: absolute;
  top: 0;
  left: 0;
}

.text-container {
  flex: 1;
  padding-right: 3rem; /* Adjust padding for spacing */
  z-index: 1; /* Ensures text is in front of the video */
}

.hero h1 {
  position: absolute;  /* Position the h1 text below the "Hello, I'm Roopa" */
  top: 22%;  /* Adjust this value to control how far from the top the h1 text is */
  left: 50%;
  transform: translateX(-50%);  /* This will center the text horizontally */
  font-size: 1rem;  /* Adjust font size */
  line-height: 1.4;
  margin-bottom: 10px;
  color: rgb(244, 238, 238); /* Darker color for better contrast */
  z-index: 2;  /* Ensure the h1 text stays on top of the video */
}

.hero p {
  position: absolute; /* Position the text absolutely */
  top: 12%; /* Adjust this value to place "Hello, I'm Roopa" slightly higher */
  left: 50%;
  transform: translateX(-50%); /* Center the text horizontally */
  font-size: 1.3rem; /* Adjust size of the "Hello, I'm Roopa" text */
  line-height: 1.6;
  color: rgb(5, 6, 23); /* Darker color for better contrast */
  margin-bottom: 10px;
  z-index: 2; /* Ensure the text stays above the video */
}

.image-container {
  flex-shrink: 1;
  max-width: 45%;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded image corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Project Portfolio Section */
.portfolio {
  margin-top: 5px; /* This adds the gap above the "Projects Portfolio" */
  padding-top: 20px; /* Adds some internal padding to give space around the title */
}

.portfolio h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px; /* Reduced this margin to create the gap you need */
  font-weight: bold;
  color: #0d63cd;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 20px;
  padding: 10px;
}

.project {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
  overflow: hidden;
  padding: 0 10px; /* Add padding on the left and right */
}


.project:hover {
  transform: translateY(-10px); /* Lift effect */
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2); /* More intense shadow */
}

.project img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.project-content {
  padding: 20px;
}

.project h3 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.project p {
  font-size: 1rem;
  line-height: 1.6;
  color: #1d1d1d;
}

.project-link {
  display: block;
  margin-top: 15px;
  color: #0072ff;
  text-decoration: none;
  font-weight: bold;
}

.project-link:hover {
  color: #005bb5; /* Hover color for links */
}

/* Styling for the In Progress Banner */
.homepage .in-progress-banner-container {
  position: relative;
  background-color: #ff5733; /* Red color */
  color: white;
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  max-width: 100%;
  width: fit-content;
  margin-top: 20px; /* Ensuring it doesn’t overlap with other elements */
  z-index: 5; /* Slightly lower z-index than DDoS project */
}

/* Responsive Design for smaller screens */

/* For Tablets (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
  .hero {
    display: flex;
    flex-direction: column; /* Stack items on top of each other */
    align-items: center;
  }

  .text-container {
    padding-right: 0;
  }

  .hero h1 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center; /* Center the heading */
  }

  .hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: center; /* Center the paragraph */
    text-justify: inter-word;
  }

  .image-container {
    max-width: 100%;
    justify-content: center;
  }

  .image-container img {
    width: 100%;
  }

  .portfolio h2 {
    font-size: 2rem;
  }

  .project-container {
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, 1fr)
    ); /* Adjust grid columns */
  }
}

/* For Mobile Screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .project {
    margin-bottom: 15px; /* Reduced bottom margin */
  }

  .portfolio h2 {
    font-size: 1.5rem;
  }

  .project h3 {
    font-size: 1.4rem;
  }

  .project p {
    font-size: 1rem;
  }

  .project-container {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
}
