.about-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 40px;
  background-color: #f4f7fc; /* Light background for the page */
}

/* About Content Styling */
.about-content {
  width: 100%;
  max-width: 1000px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Description Section */
.description {
  text-align: left;
  margin-bottom: 30px;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 2px solid #0072ff;
  padding-bottom: 5px;
}

/* Layout for the Description Content */
.description-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* Left Content Styling (Text) */
.left-content {
  flex: 1;
  text-align: justify;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-right: 20px;
}

/* Right Content Styling (Image) */
.right-content {
  max-width: 45%;
  margin-left: 20px;
}

img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Education and Achievements List */
h3 {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.8;
}

li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}

li:before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 0;
  color: #0072ff; /* Blue checkmark color */
  font-size: 18px;
}

/* Resume Button Container */
.resume-button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.resume-button {
  background-color: #0072ff;
  color: white;
  padding: 12px 25px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.resume-button:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

/* Styling for certificate links */
.certificate-link {
  color: #0072ff; /* Blue color for the certificate link */
  text-decoration: none; /* Remove underline */
  display: block; /* Makes sure it’s on a new line */
  margin-top: 5px; /* Add a little spacing between the description and the link */
}

.certificate-link:hover {
  color: #005bb5; /* Darker blue on hover */
  text-decoration: underline; /* Add underline when hovered */
  cursor: pointer; /* Show pointer cursor to indicate the link is clickable */
}

/* Responsive Design for Small Screens */
@media only screen and (max-width: 768px) {
  .description-content {
    flex-direction: column;
    align-items: center;
  }

  .left-content {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .right-content {
    max-width: 80%;
    margin-left: 0;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  ul {
    font-size: 1.1rem;
  }
}

