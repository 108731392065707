.exobrain-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .video-container {
    margin-top: 20px; 
  }
  .star-model-heading {
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 10px;
    text-align: center;
  }
  .star-model-task-heading {
    font-size: 18px; 
    margin-left: 20px; 
  }
  
  .star-model-description {
    text-align: justify;
    font-size: 18px;
    margin-left: 20px;
  }