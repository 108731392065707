/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Global reset for margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

/* Body styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6; /* Increase line height for better readability */
  font-size: 16px; /* Default font size */
  background-color: #f4f4f4; /* Light background color */
  color: #333; /* Dark text color for contrast */
}

/* Code block styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Anchor tags (links) */
a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherit color from parent element */
}

/* Button styles */
button {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #0072ff; /* Primary blue color */
  color: white;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

/* Responsive design with media queries */

/* For Tablets and smaller desktops (max width 1024px) */
@media only screen and (max-width: 1024px) {
  body {
    font-size: 15px; /* Slightly smaller font size */
  }

  button {
    padding: 8px 16px; /* Slightly smaller buttons on tablets */
  }
}

/* For Mobile Phones (max width 768px) */
@media only screen and (max-width: 768px) {
  body {
    font-size: 14px; /* Smaller font size on mobile */
  }

  button {
    padding: 8px 15px; /* Smaller buttons on mobile */
  }
}

/* For very small screens (max width 480px) */
@media only screen and (max-width: 480px) {
  body {
    font-size: 13px; /* Further reduce font size for very small screens */
  }

  button {
    padding: 6px 12px; /* Even smaller buttons on very small screens */
  }
}
